<template>
  <section id="home" class="section bg-gray">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-md-6">
          <div class="home-wrapper">
            <h1 class="animated fadeInDown wow text-center" data-wow-delay=".1s">
              {{$t("Administration,development")}}
              <span
                class="text-colored"
              >{{$t("and deployment.")}}</span>
            </h1>
            <h3
              class="animated fadeInDown wow text-muted"
              data-wow-delay=".2s"
            >{{$t("We guarantee excellent service at any stage.")}}</h3>
            <!-- <a
              href
              class="btn btn-primary btn-shadow btn-rounded w-lg animated fadeInDown wow"
              data-wow-delay=".4s"
            >{{$t("Get Started")}}</a>-->
            <div class="clearfix"></div>
          </div>
        </div>

        <div class="col-md-6">
          <div class="home-wrapper">
            <div class="animated fadeInDown wow" data-wow-delay=".1s">
              <img src="../../assets/images/worker.png" class="img-fluid" />
            </div>
          </div>
        </div>
      </div>

      <div class="row align-items-center text-center">
        <div class="col-lg-12 mb-5">
          <div class="brand-item animated fadeInLeft wow" data-wow-delay=".1s">
            <a href="https://www.mcafee.com/es-es/index.html" target="_blank">
              <img width="130" alt="McAfee" src="../../assets/images/clients_kurlabs/McAfee.png" />
            </a>
          </div>
          <div class="brand-item animated fadeInLeft wow" data-wow-delay=".2s">
            <a href="https://www.netlife.ec/" target="_blank">
              <img width="130" alt="Netlife" src="../../assets/images/clients_kurlabs/Netlife.png" />
            </a>
          </div>
          <div class="brand-item animated fadeInLeft wow" data-wow-delay=".3s">
            <a href="https://www.grupotvcable.com/" target="_blank">
              <img width="130" alt="TvCable" src="../../assets/images/clients_kurlabs/TvCable.png" />
            </a>
          </div>
          <div class="brand-item animated fadeInLeft wow" data-wow-delay=".4s">
            <a href="http://www.aztecacomunicaciones.com/" target="_blank">
              <img width="130" alt="Azteca" src="../../assets/images/clients_kurlabs/Azteca.png" />
            </a>
          </div>
          <div class="brand-item animated fadeInLeft wow" data-wow-delay=".5s">
            <a href="https://www.sabumatech.com/" target="_blank">
              <img
                width="130"
                alt="Sabumatech"
                src="../../assets/images/clients_kurlabs/Sabumatech.png"
              />
            </a>
          </div>
          <div class="brand-item animated fadeInLeft wow" data-wow-delay=".5s">
            <a href="https://alerty.online/" target="_blank">
              <img width="130" alt="Alerty" src="../../assets/images/clients_kurlabs/Alerty.png" />
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "Home"
};
</script>
